#root {
  width: 100%;
  height: 100%;
}
.App {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 100% 0;
  .logo {
    width: 23%;
    padding-top: calc(100% * 0.185);
  }

  .wheel-container {
    width: 723px;
    height: 1011px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: center bottom;
    background-repeat: no-repeat;
    background-position: 50% 0;
    .canvas-container {
      width: 723px;
      height: 723px;
      position: absolute;
      left: 0;
      touch-action: none;
      top: 133px;
      canvas {
        pointer-events: none;
      }
    }
    .shadow {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      left: 0;
      top: -1px;
      pointer-events: none;
    }
    .indicator {
      position: absolute;
      z-index: 3;
      width: 100px;
      height: 252px;
      left: 313px;
      top: 161px;
    }
  }
  .text-container {
    text-align: right;
    position: absolute;
    transform-origin: right bottom;
    right: 2%;
    bottom: 2%;
    .big {
      font-family: "gilroyBold";
      font-size: 60px;
      line-height: 73px;
      text-transform: uppercase;
    }
    .small {
      font-family: "gilroyLight";
      font-size: 30px;
      line-height: 35px;
    }
  }
  .block-container {
    text-align: center;
    position: absolute;
    transform-origin: left;
    width: 90%;
    left: 50%;
    bottom: 30%;
    .big {
      font-family: "gilroyBold";
      font-size: 60px;
      line-height: 73px;
      text-transform: uppercase;
    }
    .small {
      font-family: "gilroyLight";
      font-size: 30px;
      line-height: 35px;
    }
  }
}
